import { usePathname, useSearchParams } from 'next/navigation';

import { useEffect } from 'react';

type NavigationalEventsProps = {
  onNavigation: (value: string) => void;
};
export const NavigationalEvents = ({
  onNavigation,
}: NavigationalEventsProps) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    const url = `${pathname}?${searchParams}`;
    onNavigation(url);
  }, [pathname, searchParams, onNavigation]);

  return null;
};
