'use client';

import { modals } from '@mantine/modals';

import { NavigationalEvents } from '@/shared/navigation/NavigationalEvents';

export const CloseModalsOnNavigation = () => {
  const handleCloseAllModals = () => {
    modals.closeAll();
  };

  return <NavigationalEvents onNavigation={handleCloseAllModals} />;
};
