'use client';
import {
  Container,
  createTheme,
  defaultVariantColorsResolver,
  Modal,
  Paper,
  Pill,
  Progress,
  rem,
  ScrollArea,
  Select,
  Switch,
  Text,
  TextInput,
  ThemeIcon,
  TypographyStylesProvider,
} from '@mantine/core';

import { fontMono, fontSans } from '@/shared/fonts';

export const THEME = createTheme({
  breakpoints: {
    xs: '36em',
    sm: '48em',
    md: '62em',
    lg: '75em',
    xl: '90em',
    xxl: '100em',
    x3l: '120em',
    x4l: '150em',
  },
  primaryColor: 'indigo',
  fontFamily: fontSans.style.fontFamily,
  fontFamilyMonospace: fontMono.style.fontFamily,
  primaryShade: 8,
  components: {
    Container: Container.extend({
      defaultProps: {
        fluid: true,
      },
      styles: {
        root: {
          paddingLeft: rem(20),
          paddingRight: rem(20),
        },
      },
    }),
    Paper: Paper.extend({
      styles: {
        root: {
          borderRadius: rem(8),
        },
      },
    }),
    Text: Text.extend({
      defaultProps: {
        fw: 500,
        span: true,
      },
    }),
    TextInput: TextInput.extend({
      styles: {
        label: {
          fontWeight: 600,
          color: 'var(--mantine-color-gray-9)',
        },
      },
    }),
    TypographyStylesProvider: TypographyStylesProvider.extend({
      styles: {
        root: {
          paddingLeft: 0,
        },
      },
    }),
    Select: Select.extend({
      styles: {
        label: {
          fontWeight: 600,
          color: 'var(--mantine-color-gray-9)',
        },
      },
    }),
    MultiSelect: Select.extend({
      styles: {
        label: {
          fontWeight: 600,
          color: 'var(--mantine-color-gray-9)',
        },
      },
    }),
    Switch: Switch.extend({
      styles: {
        label: {
          fontWeight: 600,
        },
      },
    }),
    ThemeIcon: ThemeIcon.extend({
      defaultProps: {
        variant: 'outline',
        size: 'sm',
      },
      styles: {
        root: {
          border: '0 solid transparent',
          backgroundColor: 'transparent',
        },
      },
    }),
    Progress: Progress.extend({
      styles: {
        root: {
          position: 'fixed',
        },
      },
    }),
    ScrollArea: ScrollArea.extend({
      defaultProps: {
        scrollbarSize: 8,
        type: 'scroll',
      },
    }),
    Modal: Modal.extend({
      defaultProps: {
        centered: true,
      },
      styles: {
        title: {
          fontWeight: 500,
        },
      },
    }),
    Pill: Pill.extend({
      defaultProps: {
        size: 'lg',
        c: 'dark.9',
      },
      styles: {
        root: {
          fontSize: 12,
          backgroundColor: 'transparent',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'var(--mantine-color-gray-4)',
          textTransform: 'uppercase',
          fontFamily: 'var(--mantine-font-family-monospace)',
        },
        label: {
          display: 'flex',
          alignItems: 'center',
        },
      },
    }),
  },
  variantColorResolver: (input) => {
    const defaultResolvedColors = defaultVariantColorsResolver(input);
    const { color, theme, variant } = input;

    if (variant === 'outline' && color === 'gray') {
      return {
        ...defaultResolvedColors,
        border: '1px solid var(--mantine-color-gray-4)',
      };
    }

    return defaultResolvedColors;
  },
});
